import domReady from '@wordpress/dom-ready';

domReady(() => {
  // Fonction pour animer le nombre
  function animateNumber(element) {
    const targetNumber = parseFloat(element.dataset.number); // Le nombre cible
    let currentNumber = 0;
    const duration = 2000; // Durée de l'animation en millisecondes
    const interval = 30; // Intervalle de mise à jour en millisecondes
    const steps = duration / interval;
    const stepValue = targetNumber / steps;

    // Interval de mise à jour
    const intervalId = setInterval(() => {
      currentNumber += stepValue;
      element.textContent = Math.round(currentNumber);

      if (currentNumber >= targetNumber) {
        clearInterval(intervalId);
        element.textContent = targetNumber; // Assurez-vous que la valeur finale est correcte
      }
    }, interval);
  }

  // Récupérez tous les éléments avec la classe "animated-number"
  const animatedNumberElements = document.querySelectorAll('.animated-number');

  // Créer un observateur d'intersection
  const intersectionObserver = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          animateNumber(entry.target);
          observer.unobserve(entry.target); // Arrêter l'observation une fois que l'élément est visible
        }
      });
    },
    { threshold: 1 }
  );

  // Commencer à observer chaque élément
  animatedNumberElements.forEach((element) => {
    intersectionObserver.observe(element);
  });
});
